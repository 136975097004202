<template>
  <div class="admin-breadcrumb">
    <b-container>
      <b-breadcrumb>
        <b-breadcrumb-item href="#">
          <router-link to="dashboard">
            <font-awesome-icon :icon="['fas', 'home']" /> Dashboard</router-link
          >
        </b-breadcrumb-item>
        <div class="action-links">
          <b-button
            @click="onPreview"
            target="_blank"
            variant="outline-secondary"
            size="lg"
            :disabled="!showPreview"
          >
            Preview <font-awesome-icon :icon="['fas', 'eye']" />
          </b-button>
          <b-button
            v-b-modal.modal-publish
            :variant="saving ? 'outline-secondary' : 'danger'"
            size="lg"
            v-if="canPublish && showPreview"
            :disabled="saving"
            :style="saving ? 'margin-right: 0' : ''"
            ><b-spinner
              small
              style="position: relative; top: -2px"
              class="mr-2"
              v-if="saving"
            ></b-spinner>
            Publish <font-awesome-icon :icon="['fas', 'globe']" />
          </b-button>
          <b-button
            @click="onLiveSite"
            variant="success"
            size="lg"
            v-else
            :disabled="!showPreview"
          >
            Live Site <font-awesome-icon :icon="['fas', 'globe']" />
          </b-button>
        </div>
      </b-breadcrumb>
    </b-container>
    <b-modal
      id="modal-publish"
      title="Publish"
      @ok="handleOk"
      ok-title="Yes"
      cancel-title="No"
    >
      <div v-if="hasPendingStatements">
        <p>
          Prior to publishing the text of your non-candidate statement(s),
          please send the text to the Election Administrator to verify that it
          is in compliance.
        </p>
        <p>
          Once your non-candidate statement is published, you will no longer be
          able to edit your <strong>name</strong> or the <strong>text</strong>.
        </p>
        <p>You will be able to add an additional statement(s) if needed.</p>
        <p class="alert alert-primary">
          Do you still want to publish your change(s)?
        </p>
      </div>
      <div v-else-if="!hasPublishedContent">
        <p>
          Once you publish your website you will not be able to edit your
          <strong>Name</strong>. Please make sure your name is correct prior to
          publishing.
        </p>
        <p class="alert alert-primary">
          Do you still want to publish your changes?
        </p>
      </div>
      <div v-else>
        <p class="alert alert-primary">
          Are you sure you want to publish your changes?
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disablePublish: true,
      candidate: null,
      saving: false,
    };
  },
  computed: {
    showPreview() {
      return this.$store.getters["candidates/showPublish"];
    },
    settings() {
      return this.$store.getters["settings/current"];
    },
    canPublish() {
      var published =
        this.$store.state.candidates.candidate.publishedSinceLastSave;
      return !published;
    },
    hasPendingStatements() {
      return this.$store.getters["candidates/hasPendingStatements"];
    },
    hasPublishedContent() {
      return this.$store.state.candidates.candidate.hasPublishedContent;
    },
  },
  created() {
    this.init();
  },
  async mounted() {
    if (this.$store.state.candidates.candidate == null) {
      this.candidate = await this.$store.dispatch(
        "candidates/getCandidate",
        this.$route.params.id
      );
    } else {
      this.candidate = this.$store.state.candidates.candidate;
    }
    this.disablePublish = this.publishedSinceLastSave();
  },
  methods: {
    onSaveContinue(event) {
      this.$emit("on-saveContinue");
      this.disablePublish = this.publishedSinceLastSave();
    },
    publishedSinceLastSave() {
      let preview = this.showPreview;

      if (this.candidate != null && this.showPreview) {
        return this.candidate.publishedSinceLastSave;
      } else {
        return true;
      }
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      this.saving = true;

      this.$nextTick(() => {
        this.$bvModal.hide("modal-publish");
      });

      setTimeout(async () => {
        try {
          let userId = this.$route.params.id;
          await this.$store.dispatch("candidates/publishCandidate", userId);
          this.loading = false;
          this.saving = true;
        } catch (error) {
          this.errorMessage = error;
        }
        this.disablePublish = true;
        this.saving = false;
      }, 1000);
    },
    onLiveSite() {
      let routeData = this.$router.resolve(
        `/${this.settings.electionYear}/` + this.candidate.slug
      );
      window.open(routeData.href, "_blank");
    },
    async onPreview() {
      await this.onSaveContinue();
      let routeData = this.$router.resolve(
        "/preview/candidate/" + this.candidate.slug
      );
      window.open(routeData.href, "_blank");
    },
    async init() {
      this.loading = true;
      try {
        if (this.$store.state.candidates.candidate == null) {
          await this.$store.dispatch(
            "candidates/getCandidate",
            this.$route.params.id
          );
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMessage = error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-breadcrumb {
  .breadcrumb {
    margin: 30px 0 30px 0;
    padding: 0;
    border: 0;
    background-color: transparent;

    .breadcrumb-item {
      margin: auto 0;
    }

    a {
      font-size: 20px;
    }

    .action-links {
      margin-left: auto;

      .btn {
        padding-left: 20px;
        padding-right: 20px;

        &:disabled {
          opacity: 0.2;
          color: #000000;
        }
      }

      .btn-outline-secondary {
        margin-right: 15px;

        &:disabled {
          opacity: 0.2;
          color: #000000;
        }
      }
    }

    @media (max-width: $sm-width) {
      .action-links {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>
